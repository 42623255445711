import * as actionTypes from "../actionTypes";

export const initGetStudentResources = (data) => ({
  type: actionTypes.INIT_GET_STUDENT_RESOURCES,
  data
});

export const getStudentResources = (payload) => ({
  type: actionTypes.GET_STUDENT_RESOURCES,
  payload
})

export const getStudentResourcesSuccess = (payload) => ({
  type: actionTypes.GET_STUDENT_RESOURCES_SUCCESS,
  payload,
});

export const getStudentResourcesError = (error) => ({
  type: actionTypes.GET_STUDENT_RESOURCES_ERROR,
  error,
});

export const initGetGreatFinds = (data) => ({
  type: actionTypes.INIT_GET_STUDENT_RESOURCES_GREAT_FINDS,
  data
});

export const getGreatFinds = (payload) => ({
  type: actionTypes.GET_STUDENT_RESOURCES_GREAT_FINDS,
  payload
})

export const getGreatFindsSuccess = (payload) => ({
  type: actionTypes.GET_STUDENT_RESOURCES_GREAT_FINDS_SUCCESS,
  payload,
});

export const getGreatFindsError = (error) => ({
  type: actionTypes.GET_STUDENT_RESOURCES_GREAT_FINDS_ERROR,
  error,
});