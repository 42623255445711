import { useRef, useState } from "react";
import "./videoPlayer.scss";

function VideoPlayer({ videoSrc, thumbnailSrc, styleRes }) {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
    if(!videoSrc){
        return null
    }      
    const handlePlayPause = (e) => {
        e.stopPropagation()
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
                setIsPlaying(false);    
            } else {
                videoRef.current.play();
                setIsPlaying(true);
            }
        }              
    }
    return (<div className="videoplayer__videoContainer" style={styleRes}>
        <video
            ref={videoRef}
            className="videoplayer__video"
            style={styleRes}
            poster={thumbnailSrc} // Replace with your thumbnail path
            onClickCapture={handlePlayPause}
            onEnded={() => setIsPlaying(false)} // Reset on video end
        >
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        {!isPlaying && (
            <button className="videoplayer__playIcon" onClick={handlePlayPause}>
                <svg width="87" height="89" viewBox="0 0 87 89" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M43.5 81.5827C63.5203 81.5827 79.75 64.9799 79.75 44.4994C79.75 24.0188 63.5203 7.41602 43.5 7.41602C23.4797 7.41602 7.25 24.0188 7.25 44.4994C7.25 64.9799 23.4797 81.5827 43.5 81.5827Z" stroke="#F3F3F3" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M36.25 29.666L58 44.4994L36.25 59.3327V29.666Z" stroke="#F3F3F3" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        )}
    </div>
    );
}

export default VideoPlayer;
