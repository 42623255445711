import * as actionTypes from "../actionTypes";

const initialState = {
  error: null,
  isLoadingGreat: false,
  isLoadingPicks: false,
  greatFinds: {
    results: []
  },
  studentResources: {
    results: []
  }
};

const studentResourcesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_STUDENT_RESOURCES:
      const dataPicks = payload.page === 1 ? { studentResources: {results:[]} } : {}
      return {
        ...state,
        isLoadingPicks: true,
        ...dataPicks
      };
    case actionTypes.GET_STUDENT_RESOURCES_SUCCESS:
      return {
        ...state,
        isLoadingPicks: false,
        studentResources: { ...payload, results: [...state?.studentResources?.results, ...payload.results] },
      };
    case actionTypes.GET_STUDENT_RESOURCES_ERROR:
      return {
        ...state,
        isLoadingPicks: false,
      };
    case actionTypes.GET_STUDENT_RESOURCES_GREAT_FINDS:
      const data = payload.page === 1 ? { greatFinds: {results:[]} } : {}
      return {
        ...state,
        isLoadingGreat: true,
        ...data
      };
    case actionTypes.GET_STUDENT_RESOURCES_GREAT_FINDS_SUCCESS:
      return {
        ...state,
        isLoadingGreat: false,
        greatFinds: { ...payload, results: [...state?.greatFinds?.results, ...payload.results] },
      };
    case actionTypes.GET_STUDENT_RESOURCES_GREAT_FINDS_ERROR:
      return {
        ...state,
        isLoadingGreat: false,
      };
    default:
      return state;
  }
};

export default studentResourcesReducer
