import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import { getResults, getResultsSuccess, getResultsError, getRecommendationsQues,setRecommendationsQuesSuccess,setRecommendationsQuesError } from "../actions";

export function* getResultsSaga ({ payload }) {
    yield put(getResults())
    try {
        const response = yield call(routes.getResults, payload);
        yield put(getResultsSuccess(response));
      } catch (error) {
        yield put(getResultsError(error));
      }
}

export function* getRecommendationsQuesSaga (payload) {
  yield put(getRecommendationsQues())
  try {
      const response = yield call(routes.getRecommendationsQuestion, payload);
      yield put(setRecommendationsQuesSuccess({...response,id:payload.id}));
    } catch (error) {
      yield put(setRecommendationsQuesError(error));
    }
}