import React, { useEffect, useRef } from "react";
import arrowLeft from "../../../../assets/grayArrowLeft.svg";
import "./Career.scss";
import classNames from "classnames";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import useCareer from "../../../../hooks/useCareer";
import { BookModal, CancelModal, Success } from "../../../../modals";
import MoreRecommendations from "../../../../modals/MoreRecommendations";
import { useState } from "react";
import VideoPlayer from "../../../../components/VideoPlayer";
import Chatbot from "../../../../components/Chatbot";
import { formatText, isHTML } from "../../../../common/utils";
{/* <video
                src={state?.unique_title.resource}
                width="200px"
                className="CareerCont__decription__contentImg"
                controls
              /> */}
const Career = () => {
  const descriptionRef = useRef(null);
  const [isDefaultHeight, setIsDefaultHeight] = useState(true);
  const [isMoreRecommendations, setIsMoreRecommendations] = useState(false)
  const { isMobile } = useWindowWidth();
  const {
    navigateBack,
    state,
    profile,
    isConfirmModal,
    modalInfo,
    chosenItem,
    handleBack,
    handlescore,
    handleClose,
    handleBookValidation,
    handleConfirm,
  } = useCareer();
  useEffect(() => {
    const descriptionHeight = descriptionRef.current?.offsetHeight || 0;
    setIsDefaultHeight(descriptionHeight > 426);
  }, [state?.description_long]);
  const handleRecommend = (val = null) => {
    setIsMoreRecommendations(!isMoreRecommendations)
    handleClose(val)
    if (!val) {
      handleBookValidation(state.id, false)
    }
  }
  const styleImgRes = isMobile ? {} : isDefaultHeight ? {} : {
    height: '440px',
  }
  const styleVideoRes = isMobile ? {} : isDefaultHeight ? {} : {
    height: '460px',
  }
  return (
    <div className="CareerCont">
      <div className="infoCont">
        <div className="CareerCont__header">
          <div
            onClick={() => {
              navigateBack();
            }}
            className={classNames("CareerCont__header__iconWrapper", {
              hide: isMobile,
            })}
          >
            <img
              src={arrowLeft}
              alt=""
              className="CareerCont__header__iconWrapper__icon"
            />
          </div>
          <div className="CareerCont__header__textCont">
            <p
              className={classNames("CareerCont__header__textCont__Profecy", {
                hide: isMobile,
              })}
            >
              {state?.unique_title?.mask_title ? state?.unique_title?.mask_title : state?.text}
            </p>
            <p className="CareerCont__header__textCont__mentor">
              <span className="CareerCont__header__textCont__mentor__key">
                Mentor:{" "}
              </span>
              {state?.first_name}
            </p>
          </div>
        </div>
        <div className="CareerCont__decription__content">
          {
            isHTML(state?.description_long) && state?.description_long ?
              <div className="CareerCont__decription" dangerouslySetInnerHTML={{ __html: state?.description_long }} />
              :
              <p className="CareerCont__decription" ref={descriptionRef}>{state?.description_long ? formatText(state.description_long) : ''}</p>
          }
          {state?.unique_title?.resource && (
            state?.unique_title?.resource_type === 'image' ? (
              <img
                src={state?.unique_title.resource}
                width="200px"
                className="CareerCont__decription__contentImg"
                alt="Resource"
                style={styleImgRes}
              />
            ) : (
              <VideoPlayer
                styleRes={styleVideoRes}
                videoSrc={state?.unique_title.resource}
              />
            )
          )}
        </div>
        <Chatbot question={state?.unique_title?.unique_title_questions} isMoreRecommendations={isMoreRecommendations}/>
      </div>
      <BookModal
        handlescore={handlescore}
        chosenItem={state}
        handleBook={handleRecommend}
        text={state?.description}
        isContentType={true}
        handleBack={() => handleBack(state.id)}
      />
      <MoreRecommendations
        title="Choose your way to learn more."
        isOpened={isMoreRecommendations}
        handleRecommend={handleRecommend}
        onClose={() => handleRecommend(null)}
        aiReportUrl={profile?.ai_report}
        careerOutlookVal={state?.unique_title}
      />
      <CancelModal
        image={modalInfo[isConfirmModal]?.image}
        title={modalInfo[isConfirmModal]?.title}
        text={modalInfo[isConfirmModal]?.text}
        text2={modalInfo[isConfirmModal]?.text2}
        isOpened={isConfirmModal}
        removeChanges={handleConfirm}
        leftBtnText='Back'
        rightBtnText='Okay'
        close={() => handleClose(null)}
      />
    </div>
  );
};

export default Career;
