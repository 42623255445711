import { call, put } from "redux-saga/effects";
import { routes } from "../../common/api/routes";
import {
  getGreatFinds,
  getGreatFindsError,
  getGreatFindsSuccess,
  getStudentResources,
  getStudentResourcesError,
  getStudentResourcesSuccess,
} from "../actions";

export function* getStudentResourcesSaga(params) {
  yield put(getStudentResources(params.data));
  try {
    const response = yield call(routes.getStudentResourcess, params.data);
    yield put(getStudentResourcesSuccess(response));
  } catch (error) {
    yield put(getStudentResourcesError(error));
  }
}
export function* getGreatFindsSaga(params) {
  yield put(getGreatFinds(params.data));
  try {
    const response = yield call(routes.getStudentResourcess,params.data);
    yield put(getGreatFindsSuccess(response));
  } catch (error) {
    yield put(getGreatFindsError(error));
  }
}