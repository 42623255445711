import React, { useState } from "react";
import classNames from "classnames";

import CongratsLeft from "../../../../../../assets/resultLeftImages.svg";
import CongratsRight from "../../../../../../assets/resultRightImages.png";
import useWindowWidth from "../../../../../../hooks/useWindowWidth";
import { BookModal } from "../../../../../../modals";
import { Success } from "../../../../../../modals";
import LongHand from "../../../../../../assets/longHand.png";
import SuccessImage from "../../../../../../assets/success.png";
import "./AiResult.scss";

const AiResult = ({
  data,
  isModalOpened,
  handleModalOpen,
  closeModal,
  chosenItem,
  handlescore,
  handleBook,
  isSuccessModalOpened,
  closeSuccessModal,
  selectedItems,
}) => {
  const { isMobile } = useWindowWidth();
  const [imagesLoaded, setImagesLoaded] = useState({
    congratsLeft: false,
    congratsRight: false,
  });

  const handleImageLoad = (imageName) => {
    setImagesLoaded((prev) => ({
      ...prev,
      [imageName]: true,
    }));
  };

  const bothImagesLoaded =
    imagesLoaded.congratsLeft && imagesLoaded.congratsRight;

  const drwaItems = (arr) => {
    let result = [];
    for (let i = 0; i < Math.round(arr.length / 2); i++) {
      if (i + 1 < data.length || i + 1 === data.length) {
        result.push(
          <div className="AiResult__answerCont__column" key={i}>
            <div className="AiResult__answerCont__column__cont">
              <div
                className="AiResult__answerCont__column__cont__startPart"
                onClick={() => {
                  handleModalOpen(arr[i].id);
                }}
              >
                <p className="AiResult__answerCont__column__cont__startPart__text">
                  {arr[i].unique_title?.mask_title ? arr[i].unique_title?.mask_title : arr[i].text}
                </p>
                <div
                  className={classNames(
                    "AiResult__answerCont__column__cont__startPart__numberWrapper",
                    {
                      choosen:
                        chosenItem?.id === arr[i].id ||
                        selectedItems.find((item) => item.id === arr[i].id),
                    },
                  )}
                >
                  <p className="AiResult__answerCont__column__cont__startPart__numberWrapper__number">
                    {i + 1}
                  </p>
                </div>
              </div>
              <p className="AiResult__answerCont__column__cont__endPart">
                {arr[i].ai}
              </p>
            </div>
            <div className="AiResult__answerCont__column__cont">
              <div
                className="AiResult__answerCont__column__cont__startPart"
                onClick={() => {
                  handleModalOpen(arr[i + Math.round(arr.length / 2)].id);
                }}
              >
                <p className="AiResult__answerCont__column__cont__startPart__text">
                  {arr[i + Math.round(arr.length / 2)]?.unique_title?.mask_title ? arr[i + Math.round(arr.length / 2)]?.unique_title?.mask_title:arr[i + Math.round(arr.length / 2)].text}
                </p>
                <div
                  className={classNames(
                    "AiResult__answerCont__column__cont__startPart__numberWrapper",
                    {
                      choosen:
                        chosenItem?.id ===
                          arr[i + Math.round(arr.length / 2)].id ||
                        selectedItems.find(
                          (item) =>
                            item.id === arr[i + Math.round(arr.length / 2)].id,
                        ),
                    },
                  )}
                >
                  <p className="AiResult__answerCont__column__cont__startPart__numberWrapper__number">
                    {i + 1 + Math.round(arr.length / 2)}
                  </p>
                </div>
              </div>
              <p
                className={classNames(
                  "AiResult__answerCont__column__cont__endPartGreen",
                  {
                    darekYellow:
                      arr[i + Math.round(arr.length / 2)].ai.includes(
                        "AI recommended",
                      ),
                  },
                )}
              >
                {arr[i + Math.round(arr.length / 2)].ai}
              </p>
            </div>
          </div>,
        );
      }
    }
    return result;
  };
  const drawItemsForMibile = (arr) => {
    return arr.map((el, i) => (
      <div className="AiResult__answerCont__columns" key={el.id}>
        <div
          className="AiResult__answerCont__columns__cont"
          onClick={() => {
            handleModalOpen(el.id);
          }}
        >
          <p className="AiResult__answerCont__columns__cont__text">{el.unique_title?.mask_title ?  el.unique_title?.mask_title :el.text}</p>
          <div
            className={classNames(
              "AiResult__answerCont__columns__cont__numberWrapper",
              {
                choosen:
                  chosenItem?.id === el.id ||
                  selectedItems.find((item) => item.id === el.id),
              },
            )}
          >
            <p className="AiResult__answerCont__columns__cont__numberWrapper__number">
              {i + 1}
            </p>
          </div>
        </div>
        <p className="AiResult__answerCont__columns__ai">{el.ai}</p>
      </div>
    ));
  };
  return (
    <>
      <div
        className={classNames({
          invisible: !bothImagesLoaded, // Apply 'invisible' class until both images are loaded
        })}
      >
        <div className={classNames("AiResult")}>
          <p className="AiResult__title">
            Here are your top 10 career scoops click to learn more
          </p>
          <div className="AiResult__answerCont">
            {isMobile ? drawItemsForMibile(data) : drwaItems(data)}
          </div>
        </div>
        <img
          src={CongratsLeft}
          alt="CongratsLeft"
          className={classNames("AiResult__CongratsLeft", {
            unvisibleCongrats: isMobile,
          })}
          onLoad={() => handleImageLoad("congratsLeft")}
        />
        <img
          src={CongratsRight}
          alt="CongratsRight"
          className={classNames("AiResult__CongratsRight", {
            unvisibleCongrats: isMobile,
          })}
          onLoad={() => handleImageLoad("congratsRight")}
        />
        <BookModal
          isModalOpened={isModalOpened}
          closeModal={closeModal}
          number={data.findIndex((item, i) => item.id === chosenItem.id) + 1}
          title={chosenItem?.unique_title?.mask_title ? chosenItem?.unique_title?.mask_title : chosenItem.text}
          image={LongHand}
          handlescore={handlescore}
          chosenItem={chosenItem}
          handleBook={handleBook}
          text={chosenItem?.description}
        />
        <Success
          image={SuccessImage}
          title="Success !"
          text="Thank you for letting us know you want to learn more! We'll take it from here based on your Career Scoops plan."
          isOpened={isSuccessModalOpened}
          close={closeSuccessModal}
        />
      </div>

      <p className="Recommendations-info">
        This page includes information from the{" "}
        <a href="https://www.onetcenter.org/database.html">
          O*NET 27.2 Database
        </a>{" "}
        by the U.S. Department of Labor, Employment and Training Administration
        (USDOL/ETA). Used under the{" "}
        <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>{" "}
        license.  Career Scoops has modified all or some of this information.
        USDOL/ETA has not approved, endorsed, or tested these modifications.
      </p>
    </>
  );
};

export default AiResult;
