import * as actionTypes from "../actionTypes";
const initialState = {
  scenarios: [],
  quesAnw: {},
  savingResult: null,
  isLoading:false,
  error: null,
};
const scenariosReducer = (state = initialState, { type, payload, error }) => {
  switch (type) {
    case actionTypes.GET_SCENARIOS:
      return {
        ...state,
        scenarios: []
      };
    case actionTypes.GET_SCENARIOS_SUCCESS:
      return {
        ...state,
        savingResult: null,
        scenarios: payload,
      };
    case actionTypes.GET_SCENARIOS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case actionTypes.GET_RECOMMENDATIONS_QUESTION:
      return {
        ...state,
        isLoading:true,
        quesAnw: {}
      };
    case actionTypes.GET_RECOMMENDATIONS_QUESTION_SUCCESS:
      return {
        ...state,
        isLoading:false,
        quesAnw: {
          ...state.quesAnw,
          [payload.id]: payload, // Update specific answer by ID
        },
      };
    case actionTypes.GET_RECOMMENDATIONS_QUESTION_ERROR:
      return {
        ...state,
        isLoading:false,
        error: payload,
      };
    case actionTypes.SET_SCENARIOS:
      return {
        ...state,
        savingLoading: true,
        savingResult: null,
      };
    case actionTypes.SET_SCENARIOS_SUCCESS:
      return {
        ...state,
        savingResult: payload,
        savingLoading: false,
      };
    case actionTypes.SET_SCENARIOS_ERROR:
      return {
        ...state,
        savingResult: null,
        savingLoading: false,
      };
    case actionTypes.RESET_SAVING_RESULT:
      return {
        ...state,
        savingResult: null
      }
    case actionTypes.GET_RESULTS:
      return {
        ...state,
        results: null,
        error: null,
        isLoading: true
      };
    case actionTypes.GET_RESULTS_SUCCESS:
      return {
        ...state,
        results: payload,
        isLoading: false
      };
    case actionTypes.GET_RESULTS_ERROR:
      return {
        ...state,
        error: error?.response?.status === 403 ? error?.response?.data : null,
        isLoading: false
      };
    case actionTypes.SET_RESULTS:
      return {
        ...state,
        isSetResultsSuccessLoaded: false
      };
    case actionTypes.SET_RESULTS_SUCCESS:
      return {
        ...state,
        isSetResultsSuccessLoaded: true
      };
    case actionTypes.SET_RESULTS_ERROR:
      return {
        ...state,
        isSetResultsSuccessLoaded: true
      };

    default:
      return state;
  }
};
export default scenariosReducer;
