import axiosClient from "./request";
import { convertToForm } from "../utils";

export const routes = Object.freeze({
  auth: async (payload) => axiosClient.post(`auth/`, payload),
  signUp: async (payload) => axiosClient.post(`signup/`, payload),
  getTags: async (payload) => axiosClient.get(`tags/`, payload),
  getMentors: async (payload) => axiosClient.get("mentors/", payload),
  getScenarios: async (payload) => axiosClient.get("scenarios/", payload),
  setScenarios: async (payload) => axiosClient.post("scenarios/", payload),
  getResults: async (payload) => axiosClient.get("result/", payload),
  getRecommendationsQuestion: async (payload) => axiosClient.get(`question/${payload.id}/refresh/answer/`),
  setResults: async (path, payload) =>
    axiosClient.patch(`result/${path}/`, payload),
  getPricingPlan: async (payload) => axiosClient.get("pricing_plan/", payload),
  setPricingPlan: async (path) =>
    axiosClient.patch(`profile/`, { pricing_plan: path }),
  getProfile: async (payload) => axiosClient.get("profile/", payload),
  setProfile: async (payload) => {
    const params = payload?.profile_image ? convertToForm(payload) : payload
    const headers = payload?.profile_image ? {
      "Content-Type": "multipart/form-data"  // Add any custom headers here
    }:{};
    return axiosClient.patch(`profile/`, params, { headers })
  },
  getRecommendations: async (payload) => axiosClient.get(`recommendations/`, payload),
  getFeedBacks: async (payload) => axiosClient.get("mentor_feedbacks/", payload),
  getSessions: async (payload) => axiosClient.get("mentor_sessions/", payload),
  getIndustries: async (payload) => axiosClient.get("industries/", payload),
  getQuotess: async (payload) => axiosClient.get("user-reviews/", { params: payload }),
  getStudentResourcess: async (payload) => axiosClient.get("school-resource/", { params: payload }),
});
