import React from "react";
import { Settings } from "./components";
import { useProfile } from "../../hooks";
import { InviteFriendModal } from "../../modals";
import Envelope from "../../assets/InviteFriendEnvelope.png";
import classNames from "classnames";
import "./Profile.scss";
import { Outlet } from "react-router-dom";
import useWindowWidth from "../../hooks/useWindowWidth";
import StudentResources from "./components/StudentResources";

const Profile = () => {
  const {
    user,
    pathname,
    settings,
    studentResourcesList,
    greatFinds,
    isInviteFriendModalopened,
    isLogOutModalOpened,
    isProfileChildRoute,
    isLoadingGreat,
    isLoadingPicks,
    handleSettingClick,
    handleLogOut,
    closeInviteFriendModal,
    openInviteFriendModal,
    openLogOutModal,
    closeLogoutModal,
    handlePagePicks,
    handlePageGreat,
  } = useProfile();
  const { width, isMobile } = useWindowWidth();
  return (
    <>
      <div
        className={classNames("profileCont", {
          bigHeight: isMobile && pathname !== "/profile",
        })}
      >
        <div className="profileCont__grid">
          <InviteFriendModal
            isOpened={isInviteFriendModalopened}
            img={Envelope}
            title="Help our Mission: Invite a Friend"
            text=""
            leftBtnText="Cancel"
            rightBtnText="Send"
            closeModal={closeInviteFriendModal}
          />
          <div
            className={classNames("profileCont__settingsCont", {
              hide: isMobile && isProfileChildRoute,
            })}
          >
            <Settings
              settings={settings}
              handleSettingClick={handleSettingClick}
              name={user?.first_name}
              aiReportUrl={user?.ai_report}
              handleLogOut={handleLogOut}
              surname={user?.last_name}
              openInviteFriendModal={openInviteFriendModal}
              openLogOutModal={openLogOutModal}
              closeLogoutModal={closeLogoutModal}
              isLogOutModalOpened={isLogOutModalOpened}
              isMobile={isMobile}
            />
          </div>
          <div
            className={classNames("profileCont__contentCont", {
              hide: isMobile && !isProfileChildRoute,
            })}
          >
            {/* {contents[pathname.split("/").pop()]} */}
            <Outlet
              context={{
                user,
              }}
            />
          </div>
        </div>
        <StudentResources isLoading={isLoadingPicks} list={studentResourcesList} title='Picks For You' handlePage={handlePagePicks} />
        <StudentResources isLoading={isLoadingGreat} list={greatFinds} title='Great Finds' handlePage={handlePageGreat} />
      </div>
    </>
  );
};

export default Profile;
