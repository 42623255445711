import React from "react";
import aboutUsTeam from "../../assets/aboutUsTeam.png";
import "./AboutUs.scss";

const AboutUs = () => {
  return (
    <section className="About">
      <div className="About-content">
        <div className="About-content__text">
          <p className="About-content__text__title">
            Discover More <span>About Us</span>
          </p>
          <p className="About-content__text__info">
            Welcome to Career Scoops®, a platform where we harness the innovative power
            of AI technology coupled with the invaluable support of a vibrant community
            to empower the young generation in uncovering and pursuing careers that resonate
            with who they are.
          </p>
        </div>
        <div className="About-content__img">
          <img src={aboutUsTeam} alt="" />
        </div>
      </div>
      <div className="About-stats">
        {false && <div className="About-stats-cont1">
          <div className="About-stats-cont1__numbers">
            <h2>12K+</h2>
            <p>Students</p>
          </div>
          <div className="About-stats-cont1__numbers">
            <h2>1500+</h2>
            <p>Mentors</p>
          </div>
          <div className="About-stats-cont1__numbers">
            <h2>259+</h2>
            <p>Organization</p>
          </div>
          <div className="About-stats-cont1__numbers">
            <h2>4.8/5</h2>
            <p>Reviewed</p>
          </div>
        </div>}
        <div className="About-stats-cont2">
          <div className="About-stats-cont2__text">
            <h2>The Importance of Our Work</h2>
            <p>
              In a rapidly evolving world, it's vital that the new generation
              is equipped with tools that meet the demands and expectations of
              today's dynamic landscape. At Career Scoops, we're dedicated to
              empowering 23 million of young individuals by providing up-to-date,
              actionable insights to help them navigate their career journey and
              position them for success in a future that is constantly changing.
            </p>
          </div>
          <div className="About-stats-cont2__text">
            <h2>Technology</h2>
            <p>
              At Career Scoops, we enhance career discovery leveraging AI technology,
              which helps us to uncover hidden potentials that might be missed by the
              human eye. Our AI-powered system matches young adults with careers
              tailored to their interests, skills, and values, offering personalized
              actionable recommendations that go beyond the surface to reveal truly
              aligned opportunities.
            </p>
          </div>
          <div className="About-stats-cont2__text">
            <h2>Our Solution</h2>
            <p>
              But we don’t stop there. We understand that career decision-making involves
              more than just receiving recommendations. That’s why we also provide a
              platform for individuals to schedule conversations with professionals in
              the field to learn more about the careers they were matched to. This provides
              an opportunity for individuals to gain valuable insights into different
              career paths and make informed decisions about their future.
            </p>
          </div>
          <div className="About-stats-cont2__text">
            <h2>Our Values</h2>
            <p>
              At Career Scoops, we're driven by the core belief in creating a stronger,
              more empowered community for the future. Recognizing the pivotal role of
              informed guidance and actionable resources in shaping young individuals'
              career trajectories, we dedicate ourselves to equipping the next generation
              with cutting-edge tools and insights. Our commitment lies in fostering an
              environment where every young person can explore, discover, and pursue their
              career aspirations with confidence and support.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
