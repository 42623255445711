import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import herroPic from "../../assets/herroPic.png";
import Home1 from "../../assets/home1.png";
import WaveWithLetters from "../../assets/Wavebluewithletters.png";
import WaveWithLettersMedium from "../../assets/WaveWithLettersMedium.png";
import WaveWithLettersSmall from "../../assets/waveblueLettersSmall.png";
import Home2 from "../../assets/home2.png";
import Home3 from "../../assets/home3.png";
import Home4 from "../../assets/home4.png";
import wave from "../../assets/wave.svg";
import Feedback from "./components/Feedback/Feedback";
import Carousel from "../Carousel";
import SpeechBubble from "./components/SpeechBubble/SpeechBubble";
import Error from "../../assets/error.svg";

import "./Home.scss";
import useNavigation from "../../hooks/useNavigation";
import useWindowWidth from "../../hooks/useWindowWidth";
import { Success } from "../../modals";

const Home = () => {
  const { isAuth, user, getIsPaidPlan, error } = useNavigation();
  const navigate = useNavigate();
  const [isErrorModalOpened, setIsErrorModalOpened] = useState(false);
  const handleLetsStart = () => {
    if(error?.detail){
      setIsErrorModalOpened(error?.detail)
    }else{
      navigate(isAuth ? "/scenario/0" : "/signin");
    }
    //   if (getIsPaidPlan()) {
    //   navigate(isAuth ? "/scenario/0" : "/signin");
    // } else {
    //   if (!isAuth) {
    //     navigate("/signin");
    //   } else {
    //     navigate("/pricingplan");
    //   }
    // }
  };
  const { width } = useWindowWidth();

  const armanFirstSpeechText = (
    <>
      Hi, I'm <span>Arman</span>. Growing up in Central Florida, I felt lost in a maze of possibilities. I wanted a life of passion and purpose, but finding my path in a fast-changing world was tough. I realized I wasn’t alone—so many others felt the same uncertainty. That’s what sparked my journey—and the creation of Career Scoops.
    </>
  );

  const armanSecondSpeechText = `The challenge is real—many young people feel uncertain about their education and career paths. Whether it’s deciding on college, exploring alternatives, or navigating life after graduation, this uncertainty can be overwhelming. At Career Scoops, we’re here to turn confusion into clarity and confidence.`;

  const armanThirdSpeechText = `Career Scoops isn’t just about making decisions—it’s about uncovering your potential and finding a future that excites you. Ready to explore what truly matters for your journey? Join Career Scoops today for a guided path of self-discovery and growth.`;

  const armanFourthSpeechText = `Passionate about mentoring young people? Become a Community Partner! Together, we can guide the next generation toward purpose and success, building a community where every young person finds their place.`;

  return (
    <section className="Home">
      <div className="Home-welcome">
        <div className="Home-welcome-content">
          <div className="Home-welcome-content-text">
            <div className="Home-welcome-content-text__title">
              <h1>
                Discover Your Passion and Build
                <span>Your Future</span>
              </h1>
            </div>
            <p>
              Finding your dream career is similar to finding your favorite ice
              cream flavor: you need to sample different options to discover
              what suits you best. We are all unique, and it's only by exploring
              various paths that we can uncover the career that resonates with
              our individual tastes and preferences.
            </p>
            {user?.role === "Mentor" ? (
              <></>
            ) : (
              <button onClick={handleLetsStart}>Let's Start</button>
            )}
          </div>
          <div className="Home-welcome-content__img">
            <img src={herroPic} alt="" />
            <p>
              Are you here to <span>EXPLORE ?</span>
            </p>
          </div>
        </div>
      </div>
      <img
        src={
          width <= 600
            ? WaveWithLettersSmall
            : width <= 991
              ? WaveWithLettersMedium
              : WaveWithLetters
        }
        alt="WaveWithLetters"
        className="waveimg"
      />
      <div className="Home-content">
        <h3>Our Story</h3>
        <div className="Home-content-items">
          <div className="item">
            <img src={Home1} alt="" />
            <SpeechBubble
              altText="armanFirstSpeechText"
              speechText={armanFirstSpeechText}
            />
          </div>
          <div className="item">
            <img src={Home2} alt="" />
            <SpeechBubble
              altText="armanSecondSpeechText"
              speechText={armanSecondSpeechText}
            />
          </div>
          <div className="item">
            <img src={Home3} alt="" />
            <SpeechBubble
              altText="armanThirdSpeechText"
              buttonLink="/new/student/"
              buttonText="Career Explorer Sign Up"
              floatSide="right"
              hasButton
              speechText={armanThirdSpeechText}
            />
          </div>
          <div className="item">
            <img src={Home4} alt="" />
            <SpeechBubble
              altText="armanFourthSpeechText"
              buttonLink="/signup/mentor"
              buttonText="Community Partner Sign Up"
              floatSide="left"
              hasButton
              speechText={armanFourthSpeechText}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "100px 0 0 0",
          }}
        >
          <p style={{ width: "656px", fontSize: "36px", color: "#174273" }}>
            Discover your match among{" "}
            <span style={{ fontWeight: "700", fontSize: "48px" }}>800+</span>{" "}
            career options tailored just for you
          </p>
        </div>
        <Carousel />
      </div>
      <Success
            image={Error}
            title=""
            text={isErrorModalOpened}
            isOpened={isErrorModalOpened}
            close={() => {
              navigate("/pricingplan");
            }}
          />
      <Feedback />
    </section>
  );
};

export default Home;
