import ScoopIco from "../../assets/chatbot_scoop.svg";
import CopyIco from "../../assets/copy_ico.svg";
import RefreshIco from "../../assets/refresh_ico.svg";
import InfoWhiteIco from "../../assets/Info_white.svg";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initGetRecommendationsQues } from "../../redux/actions";
import Loader from "../Loader/loaderSpin";
import { useEffect } from "react";
import { formatText, isHTML } from "../../common/utils";
export default function Chatbot({ question, isMoreRecommendations }) {
    const dispatch = useDispatch();
    const { quesAnw, isLoading } = useSelector((state) => state.scenariosReducer);
    const [copyMessage, setCopyMessage] = useState('')
    const [countRefresh, setCountRefresh] = useState({})
    const [openAnswerId, setOpenAnswerId] = useState(false)
    const [typingAnswer, setTypingAnswer] = useState('');
    const typingIntervalRef = useRef(null); // Ref to store the interval ID
    useEffect(()=>{
        if(isMoreRecommendations){
            setOpenAnswerId(false)
        }
    },[isMoreRecommendations])
    const copyToClipboard = async (htmlElement) => {
        try {
            const divElement = document.createElement("div");
            divElement.innerHTML = htmlElement;
            const text = divElement.innerText || divElement.textContent;
            await navigator.clipboard.writeText(text);
            setCopyMessage("Copied successfully!");
            // Hide the message after 3 seconds (optional)
            setTimeout(() => {
                setCopyMessage("");
            }, 3000);
        } catch (err) {
            //   setMessage("Failed to copy text.");
            console.error("Error copying text: ", err);
        }
    };
    useEffect(() => {
        if (typingIntervalRef.current) {
            clearInterval(typingIntervalRef.current);
        }
        if (openAnswerId) {
            const answer = quesAnw[openAnswerId]?.answer || question.find(q => q.id === openAnswerId)?.answer?.answer;
            if (answer) {
                simulateTyping(answer); // Trigger typing effect once the answer is ready
            }
        }
        return () => {
            // Clear the interval when the component unmounts or state changes
            if (typingIntervalRef.current) {
                clearInterval(typingIntervalRef.current);
            }
        };
    }, [quesAnw, openAnswerId]);
    const handleToggleAnswer = (id) => {
        setCopyMessage("");
        setTypingAnswer('');
        setOpenAnswerId(openAnswerId === id ? null : id);
    }
    const handleRefreshAnw = (id) => {
        setCountRefresh({
            ...countRefresh,
            [id]: (countRefresh[id] || 1) + 1
        })
        dispatch(initGetRecommendationsQues(id))
    }
    const simulateTyping = (htmlString) => {
        if (!htmlString) return;
        const text = htmlString.replace(/<\/?[^>]+(>|$)/g, "")
        setTypingAnswer(text.charAt(0));
        let index = 0;
        typingIntervalRef.current = setInterval(() => {
            if (index < text.length) {
                setTypingAnswer((prev) => prev + text.charAt(index));
                index++;
            } else {
                clearInterval(typingIntervalRef.current);
            }
        }, 20); // Adjust speed as needed
    };
    if (!question?.length) {
        return null
    }
    return <div className="CareerCont__chatbot">
        <h3>Get the Scoop on This Career <img src={ScoopIco} alt='Scoop' /></h3>
        <ul>
            {
                question?.map(items => <li key={items.id}>
                    <button type="button" className={`badge ${openAnswerId === items.id && 'active'}`} onClick={() => handleToggleAnswer(items.id)}>{items.question}</button>
                    {openAnswerId === items.id && <div className="CareerCont__chatbot__des">
                        <span>{countRefresh[items.id] || 1}/5 scoops</span>
                        {isLoading ? <Loader /> : isHTML(items.answer?.answer) ? <div dangerouslySetInnerHTML={{ __html: typingAnswer }}></div>:<p>{formatText(typingAnswer)}</p>}
                        <div className="CareerCont__chatbot__des__btns">
                            {copyMessage && <div className="CareerCont__chatbot__des__message">{copyMessage}</div>}
                            <button disabled={isLoading} type="button" onClick={() => copyToClipboard(quesAnw[items.id]?.answer || items.answer?.answer)}><img src={CopyIco} alt='copy' /></button>
                            <button disabled={isLoading || countRefresh[items.id] === 5} className={countRefresh[items.id] === 5 && 'btn-disabled'} type="button" onClick={() => handleRefreshAnw(items.id)}><img src={RefreshIco} alt='refresh' /></button>
                            <button disabled={isLoading} type="button" onClick={() => handleToggleAnswer(null)}>Back</button>
                        </div>
                    </div>}
                </li>)
            }
        </ul>
        <form className="CareerCont__chatbot__input">
            <input type='text' placeholder='Select an option above...' disabled />
            <div className="tooltip-container">
                <button type="button">
                    <img src={InfoWhiteIco} alt='info_ico' />
                </button>
                <span className="tooltip-text CareerCont__chatbot__des__message">AI Career Buddy is only available with certain subscriptions</span>
            </div>
        </form>
    </div>
}