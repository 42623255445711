import React from "react";
import useMyPlan from "../../../../hooks/useMyPlan";
import "./MyPlan.scss";
import classNames from "classnames";
import useWindowWidth from "../../../../hooks/useWindowWidth";
import { Link } from "react-router-dom";

const MyPlan = () => {
  const { renderFoodItems, pricingPlans, user } = useMyPlan();
  const foodItems = pricingPlans.filter(plan => plan.id === user?.pricing_plan);
  const plan = foodItems?.length ? foodItems[0]?.title : ''
  const { isMobile } = useWindowWidth()
  return (
    <>
      <div
        className={classNames("profileCont__contentCont__activePlanText")}
      >{!user?.pricing_plan ?'Free' : 'Active'} Plan</div>
      {!user?.pricing_plan ? <p className={classNames("myPlanCont__item__descriptionCont__noContent")}>Upgrade your plan to access exclusive features and tools.</p>:null}
      <div className="myPlanCont">
        {renderFoodItems(foodItems)}
        {plan !== 'Milkshake' ? <div className="myPlanCont__btnWrapper">
          <button className="myPlanCont__btnWrapper__btn">
            <Link to="/pricingplan" className="myPlanCont__btnWrapper__btn__text">Change The Plan</Link>
          </button>
        </div> : null}
      </div>
    </>
  );
};

export default MyPlan;
